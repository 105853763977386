.recharts-cartesian-axis text {
  fill: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.recharts-cartesian-axis-line {
  stroke: #d8dae5;
}
