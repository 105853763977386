html {
  background-color: #ffffff;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.MuiDataGrid-root{
  --unstable_DataGrid-radius: none !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 16px;
  scrollbar-color: #eaecf0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  /*width: 16px;*/
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 7px;
  border: 4px solid white;
}

a {
  text-decoration: none;
  color: #126fe9;
}

a:active {
  color: -webkit-link;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiOutlinedInput-input {
  color: #101828;
}

.MuiOutlinedInput-input::placeholder {
  opacity: 1;
  color: #667085;
}

.MuiButton-sizeLarge {
  padding: 16px 18px !important;
}

.MuiButton-root {
  padding: 10px 18px;
  border-radius: 8px;
}

.MuiOutlinedInput-root {
  border-color: #d0d5dd;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.04),
    0 3px 2px -1px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  font-size: 14px;
}

.MuiInputLabel-root {
  font-size: 1.1rem;
  color: #101828;
}

#notistack-snackbar {
  align-items: flex-start;
  padding: 14px 0;
  max-width: calc(100% - 56px);
}

input {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

textarea {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.MuiAutocomplete-option {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 8px !important;
  margin: 0 8px !important;
  border-radius: 8px !important;
  padding: 8px !important;
}

.MuiAutocomplete-option.Mui-focused {
  background: #f6f6f6 !important;
}

.MuiAutocomplete-paper {
  font-size: 14px;
}

.MuiTablePagination-menuItem {
  font-size: 14px;
}

.MuiTabs-root {
  min-height: 36px !important;
}

.MuiMenuItem-root {
  padding: 8px;
  font-size: 14px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #101828;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #126fe9;
}

.MuiAutocomplete-paper {
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d5dd;
  margin-top: 2px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #d0d5dd;
}

.MuiInputBase-input {
  color: #101828 !important;
}
.MuiInputBase-input::placeholder {
  opacity: 1;
  color: #667085;
}

.MuiAutocomplete-endAdornment {
  top: unset;
}

.MuiDataGrid-columnHeaderTitle {
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #667085 !important;
}

.MuiTablePagination-menuItem.Mui-selected {
  background: #eef3ff !important;
}

.MuiTablePagination-menuItem {
  border-radius: 8px !important;
  color: #101828 !important;
}

.MuiTablePagination-menuItem:hover {
  background: #f6f6f6 !important;
}

.MuiPopover-paper {
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06),
    0px 12px 16px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ededed;
}

.MuiPopover-paper .MuiTablePagination-menuItem {
  border-radius: 8px !important;
  margin: 0 8px !important;
}

.MuiDrawer-paper {
  transform: none !important;
  transition: none !important;
}

.MuiDataGrid-pinnedColumnHeaders {
  background: #fafafa !important;
  padding-right: 0 !important;
}

.MuiDataGrid-pinnedColumns {
  min-height: auto !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}


#beacon-container-body {
  display: none !important;
}
